import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../src/pages/home";
import Homechef from "../src/pages/homeChef";
import Homedarknew from "../src/pages/homeDarknew";
import Homedesigner from "../src/pages/homeDesigner";
import Homegrey from "../src/pages/homeGrey";
import Homelawyer from "../src/pages/homeLawyer";
import Homelight from "../src/pages/homeLight";
import Homelightnew from "../src/pages/homeLightnew";
import Homeparticle from "../src/pages/homeParticle";
import Homereveal from "../src/pages/homeReveal";
import Homerevealdarknew from "../src/pages/homeRevealDarknew";
import Homereveallight from "../src/pages/homeRevealLight";
import Homereveallightnew from "../src/pages/homeRevealLightnew";
import Homevideo from "../src/pages/homeVideo";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/homelight" element={<Homelight />} />
          <Route path="/homevideo" element={<Homevideo />} />
          <Route path="/homeparticle" element={<Homeparticle />} />
          <Route path="/homegrey" element={<Homegrey />} />
          <Route path="/homechef" element={<Homechef />} />
          <Route path="/homedesigner" element={<Homedesigner />} />
          <Route path="/homelawyer" element={<Homelawyer />} />
          <Route path="/homelightnew" element={<Homelightnew />} />
          <Route path="/homedarknew" element={<Homedarknew />} />
          <Route path="/homereveal" element={<Homereveal />} />
          <Route path="/homereveallight" element={<Homereveallight />} />
          <Route path="/homerevealdarknew" element={<Homerevealdarknew />} />
          <Route path="/homereveallightnew" element={<Homereveallightnew />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
